/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

export default {
    /**
     * 格式化模版, 例如:
     * format('hello, {name}', {name:'tom'}) 返回: hello, tom
     * @param str 字符串模版
     * @param obj 模版变量对应的对象
     */
    format: (str, obj) => {
        if (!obj) {
            return str
        }
        return str.replace(/\{([^}]+)\}/g, (_match, key) => obj[key])
    },
    getRandomInt(min = 1, max = 100) {
        return Math.floor((Math.random() * max) + min)
    },
    parseParams: (querystring = window.location.search) => {
        const params = new URLSearchParams(querystring)
        const obj = {}
        // iterate over all keys
        for (const key of params.keys()) {
            if (params.getAll(key).length > 1) {
                obj[key] = params.getAll(key)
            } else {
                obj[key] = params.get(key)
            }
        }
        return obj
    },
    formatDuration(duration) {
        if (duration > 3600000) {
            return `${(duration / 3600000).toFixed(2)}小时`
        } else if (duration > 60000) {
            return `${Math.ceil(duration / 60000)}分钟`
        } else if (duration) {
            return `${duration / 1000}秒`
        } else {
            return '0'
        }
    },
}
