/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useRef, useState } from "react"
import { Card, Toast, Button, Empty } from 'antd-mobile'
import api from "../../api"
import styles from './index.module.less'
import { useNavigate } from "react-router-dom"


function Index(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const toast = useRef(null)
    const loadZones = useCallback(async () => {
        setLoading(true)
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgZones()
        if (res.meta.code === 200) {
            setList(res.data)
        } else {

        }
        toast.current.close()
        setLoading(false)
    }, [])

    const handleEdit = useCallback(async (_id) => {
        navigate(`/zones/edit?id=${_id}`)
    }, [navigate])


    useEffect(() => {
        loadZones()
    }, [])
    return <div className={styles.zones}>
        <div className={styles.action}>
            <Button size="small" onClick={() => {
                navigate('/zones/add')
            }}>添加</Button>
        </div>
        {list.map((_item) => {
            return <Card title={_item.name} key={_item.id} onClick={() => {
                handleEdit(_item.id)
            }} className={styles.card}>
                <div className={styles.content}>
                    <span>学生：{_item.staff_count}</span>
                    <span>老师：{_item.user_count}</span>
                </div>
            </Card>
        })}
        {!loading && !list.length ? <><Empty description="暂无数据" /></> : ''}
    </div>
}

export default Index
