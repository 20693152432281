/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/28
 */

import { List, Avatar, Button, Toast, Card, Dialog } from 'antd-mobile'
import helper from "../../../utils/helper"
import styles from './index.module.less'
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import api from "../../../api"
import Calendar from "./calendar"
import Summary from "./summary"
import Progress from "./progress"
import { useNavigate } from "react-router-dom"

function Detail() {
    const navigate = useNavigate()
    const params = helper.parseParams()
    const [user, setUser] = useState({})
    const [data, setData] = useState({})
    const mounted = useRef(null)
    const loadDate = useCallback(async () => {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        const _user = await api.getOrgStudent(params.id)
        const res = await api.getOrgStudentSummary(params.id, params)
        if (mounted.current) {
            setUser(_user.data)
            setData(res.data)
        }
        Toast.clear()
    }, [])
    useEffect(() => {
        mounted.current = true
        loadDate()
        return () => [
            mounted.current = false
        ]
    }, [])

    const handleEdit = useCallback(async (_id) => {
        navigate(`/students/edit?id=${_id}`)
    }, [navigate])

    const description = useMemo(() => {
        return <div className={styles.description}>
            <span className={styles.label}>{user.grade}年级</span>
            <span className={styles.label}>{user.class_num}班</span>
            <span className={styles.label}>{user.school}</span>
        </div>
    }, [user])

    const handleDisabled = useCallback(async () => {
        console.log(user)
        Toast.show({
            icon: 'loading',
            content: '处理中…',
        })
        const res = await api.enableOrgStudent(params.id, { enabled: !user.enabled })
        console.log(res)
        if (res.meta.code === 200) {
            loadDate()
        }
        Toast.clear()
    }, [loadDate, params.id, user.enabled])


    return <div className={styles.detail}>
        <div className={styles.user}>
            <List mode="card">
                <List.Item
                    prefix={<Avatar style={{ '--size': '44px' }} />}
                    description={description}
                    extra={user.id ? <div className={styles.extra}><Button size="small" onClick={() => {
                        handleEdit(user.id)
                    }}>修改</Button><Button size="small"
                                          onClick={() =>
                                              Dialog.confirm({
                                                  content: `确认${user.enabled ? '停用' : '启用'}？`,
                                                  onConfirm: async () => {
                                                      handleDisabled()
                                                  }
                                              })
                                          }
                    >{user.enabled ? '停用' : '启用'}</Button></div> : ''}>
                    {user.name}<span className={styles.mobile}>{user.mobile}</span>
                </List.Item>
            </List>
        </div>
        <div className={styles.main}>
            <Calendar userId={params.id} />
            <Card title="学习概况">
                <Summary userId={params.id} data={data.summary} />
                <Progress title="课内" data={data.k12_user_outlines || []} />
                <Progress title="课外" data={data.specific_user_outlines || []} />
            </Card>
        </div>

    </div>
}

export default Detail
