/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import styles from './Base'
import Header from './Header'

function Base(props) {
    return <div style={{ height: '100%' }}>
        <Header {...props} />
        <div className={styles.layout}>
            <div className={styles.contentWrap}>
                {props.children}
            </div>
        </div>
    </div>

}

export default Base
