/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card, Empty } from 'antd-mobile'
import styles from './index.module.less'

function Index(props) {
    const { data } = props
    return <div className={styles.progress}>
        <Card title={props.title}>
            {
                data.length ?
                    <ul>
                        {data.map((item, idx) => {
                            return <li key={idx}>
                                <div className={styles.label}>{item.subject_name}</div>
                                <div className={styles.chapterWrap}>
                                    <div className={styles.chapter}>{item.path.map((_p) => {
                                        return _p.name
                                    })}</div>
                                    <div className={styles.num}>学习进度：{item.knowledge_percent}%</div>
                                </div>
                            </li>
                        })}
                    </ul>
                    : <Empty description="暂无数据" />
            }
        </Card>
    </div>
}

export default Index
