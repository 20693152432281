/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { List, Card, Tag, Toast } from 'antd-mobile'
import { useCallback, useEffect, useRef, useState } from "react"
import {
    UserContactOutline,
    UserSetOutline,
    AppstoreOutline,
    PayCircleOutline,
    UploadOutline
} from 'antd-mobile-icons'
import api from "../../api"
import styles from './index.module.less'
import { useNavigate } from "react-router-dom"
import constant from '../../constants'


function Index(props) {
    const navigate = useNavigate()
    const [me, setMe] = useState(null)
    const toast = useRef(null)
    const loadMe = useCallback(async () => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgStaffMe()
        if (res.meta.code === 200) {
            res.data['role_name'] = constant.roleKV[res.data.role_id]
            setMe(res.data)
        } else {

        }
        toast.current.close()
    }, [])

    const handleLogout = useCallback(async () => {
        window.localStorage.removeItem('access_token_h5')
        window.location.href = '/login'
    }, [])

    useEffect(() => {
        loadMe()
    }, [])
    return <div className={styles.me}>
        {me ?
            <div className={styles.card}>
                <Card title={me?.name}
                      extra={
                          me.role_id !== 3 ? <Tag color="primary" fill="outline">{me?.role_name}</Tag> : ''
                      }>{me?.mobile}</Card>
            </div>
            : ''}
        <div className={styles.nav}>
            <List>
                <List.Item prefix={<UserContactOutline />} onClick={() => {
                    navigate('/students')
                }}>学生管理</List.Item>
                {me?.role_id === 1 || me?.role_id === 2 ?
                    <>
                        <List.Item prefix={<UserSetOutline />} onClick={() => {
                            navigate('/staffs')
                        }}>员工管理 </List.Item>
                        <List.Item prefix={<AppstoreOutline />} onClick={() => {
                            navigate('/zones')
                        }}>校区管理</List.Item>
                        <List.Item prefix={<PayCircleOutline />} onClick={() => {
                            navigate('/charges')
                        }}>消费记录</List.Item>
                    </>
                    : ''}
                <List.Item prefix={<UploadOutline />} onClick={handleLogout}>退出</List.Item>
            </List>
        </div>
    </div>
}

export default Index
