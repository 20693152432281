/**
 * 文件说明: from chatGPT
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import React, { useState, useEffect } from "react"
import { Button } from "antd-mobile"

function CountdownButton(props) {
    let timer
    // initial time (in seconds)
    const [time, setTime] = useState(60)

    // whether the button is disabled
    const [disabled, setDisabled] = useState(false)

    // start countdown
    const startCountdown = () => {
        setDisabled(true)
        setTime(60)
        props.onClick()
    }

    // update time every second
    useEffect(() => {

        if (disabled) {
            timer = setTimeout(() => {
                setTime((prevTime) => prevTime - 1)
            }, 1000)
        }

        // stop countdown when time reaches zero
        if (time === 0) {
            setDisabled(false)
            clearTimeout(timer)
        }

        // clean up timer
        return () => clearTimeout(timer)
    }, [disabled, time])

    useEffect(() => {
        if (props.disabled) {
            setDisabled(false)
            setTime(60)
            clearInterval(timer)
        }
    }, [props.disabled])

    return (
        <Button onClick={startCountdown} size="small" disabled={props.disabled || disabled}>
            {disabled ? `重新发送(${time}s)` : "获取验证码"}
        </Button>
    )
}

export default CountdownButton
