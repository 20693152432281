/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Picker, Toast, Button, Form, Input, Dialog, Radio } from 'antd-mobile'
import api from "../../../api"
import styles from './index.module.less'
import helper from '../../../utils/helper.js'
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import constant from '../../../constants'

const ClassNum = []
for (let i = 1; i < 31; i++) {
    ClassNum.push({ value: i, label: `${i}班` })
}


function Index(props) {
    const stages = useSelector((state) => state.stages.list)
    const params = helper.parseParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [zones, setZones] = useState([])
    const [record, setRecord] = useState(null)
    const [amount, setAmount] = useState(null)
    const [mobile, setMobile] = useState('')
    const [visible, setVisible] = useState(false)
    const [visibleGrades, setVisibleGrades] = useState(false)
    const [visibleClass, setVisibleClass] = useState(false)
    const toast = useRef(null)
    const loadZones = useCallback(async () => {
        const res = await api.getZones({ limit: 1000 })
        const _zones = []
        res.data.forEach((_item) => {
            _zones.push({ value: _item.id, label: _item.name })
        })
        setZones(_zones)
    }, [])
    useEffect(() => {
        loadZones()
    }, [])
    const loadStaff = useCallback(async (id) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgStudent(id)
        if (res.meta.code === 200) {
            setRecord(res.data)
        } else {

        }
        toast.current.close()
    }, [])

    const loadAmount = useCallback(async () => {
        const res = await api.getAmount()
        if (res.meta.code === 200) {
            setAmount(res.data)
        }
    }, [])


    useEffect(() => {
        loadAmount()
        if (params.id) {
            loadStaff(params.id)
        }
    }, [])

    useEffect(() => {
        if (record && zones.length) {
            const _zone = zones.find((_item) => _item.value === record.zone_id)
            const _data = {
                name: record.name,
                mobile: record.mobile,
                school: record.school,
                remark: record.remark,
                stage: record.stage,
                zone_id: record.zone_id,
                grade: record.grade,
                class_num: record.class_num
            }
            if (_zone) {
                _data.zone = _zone.label
            }
            if (ClassNum[record.class_num + 1]) {
                _data.class_name = ClassNum[record.class_num - 1].label
            }
            let _grade = constant.grades.find((_g) => _g.value === record.grade)
            if (_grade) {
                _data.grade_name = _grade.label
            }
            form.setFieldsValue(_data)
        }
        return () => {
        }
    }, [record, zones, form])

    const save = useCallback(async (value) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '处理中...',
            duration: 0
        })
        let res = null
        if (record) {
            res = await api.putOrgStudent(record.id, value)
        } else {
            res = await api.postOrgStudent(value)
        }
        if (res.meta?.code === 200) {
            if (record?.id) {
                navigate(`/students/detail?id=${record.id}`)
            } else {
                navigate(`/students`)
            }
            toast.current.close()
        } else {
            toast.current = Toast.show({
                icon: 'fail',
                content: res.meta.message,
                duration: 3000
            })
        }
    }, [navigate, record])


    const handleFinish = useCallback(async (value) => {
        if (params.id) {
            await save(value)
        } else {
            Dialog.confirm({
                content: `开通账号，需消耗 ${amount?.amount}豆，确定开通？`,
                onConfirm: async () => {
                    await save(value)
                }
            })
        }
    }, [amount?.amount, params.id, save])

    const initValue = useMemo(() => {
        return {
            stage: 1
        }
    }, [])

    const handleFieldsChange = useCallback(async (val) => {
        if (val[0].name[0] === 'mobile') {
            setMobile(val[0].value)
        }
    }, [])

    const handleZone = useCallback(async () => {
        setVisible(true)
    }, [])

    const handleSelectedZone = useCallback(async (val) => {
        if (val.length) {
            const _zone = zones.find((_item) => _item.value === val[0])
            form.setFieldsValue({
                'zone_id': _zone.value,
                'zone': _zone.label
            })
        }
    }, [form, zones])

    const handleGrades = useCallback(async () => {
        setVisibleGrades(true)
    }, [])

    const handleSelectedGrade = useCallback((val) => {
        if (val.length) {
            const _grade = constant.grades.find((_item) => _item.value === val[0])
            form.setFieldsValue({
                'grade_name': _grade.label,
                'grade': _grade.value
            })
        }
    }, [form])

    const handleClass = useCallback(async () => {
        setVisibleClass(true)
    }, [])

    const handleSelectedClass = useCallback((val) => {
        if (val.length) {
            const _grade = ClassNum.find((_item) => _item.value === val[0])
            form.setFieldsValue({
                'class_name': _grade.label,
                'class_num': _grade.value
            })
        }
    }, [form])

    const handleTip = useCallback(async () => {
        Dialog.alert({
            content: <div className={styles.tipWrap}>
                <h4>费用说明</h4>
                <p>按自然月计算，每月1日 00：01一次性扣除启用账号本月费用，每个启用账号，500豆/月；若不足一个月，则按剩余天数换算，一次性扣除本月费用；</p>
                <p>
                    换算原则：<br />
                    启用账号当天算在内一并扣除；<br />
                    开通新账号当天不算在内<br />
                    换算后不整，则进行四舍五入
                </p>
            </div>,
            closeOnMaskClick: true,
        })
    }, [])


    return <div className={styles.staffs}>
        {amount && !params.id ?
            <div className={styles.amountTip}>今日开通账号需消耗：{amount.amount}豆 <a href={null} onClick={handleTip}>费用说明</a>
            </div> : ''}
        <div className={styles.formWrap}>
            <Form
                name="form"
                form={form}
                initialValues={initValue}
                onFinish={handleFinish}
                onFieldsChange={handleFieldsChange}
                footer={
                    <>
                        <div
                            className={styles.password}>{mobile.length === 11 ? `初始密码：${mobile.substring(5, 11)}` : ''}</div>
                        <Button block type="submit" color="primary" shape="rounded"
                                size="large">{params.id ? '保存' : '开通'}</Button>
                    </>
                }
            >
                <Form.Item name="name" label="姓名"
                           rules={[{ required: true, message: '姓名不能为空' }]}>
                    <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item name="mobile" label="手机号" inputMode="decimal" type="tel"
                           rules={[{ required: true, message: '手机号错误' }]}>
                    <Input placeholder="请输入手机号" />
                </Form.Item>
                {stages.length ?
                    <Form.Item name="stage" label="身份"
                               rules={[{ required: true, message: '身份不能为空' }]}>
                        <Radio.Group>
                            {stages.map((_item) => {
                                return <Radio key={_item.id} value={_item.id}
                                              className={styles.radio}>{_item.name}</Radio>
                            })}
                        </Radio.Group>
                    </Form.Item>
                    : ''}
                <Form.Item name="zone" label="所在校区" rules={[{ required: true, message: '校区不能为空' }]}>
                    <Input placeholder="请选择所在校区" readOnly={true} onClick={handleZone} />
                </Form.Item>
                <Form.Item name="school" label="学校">
                    <Input />
                </Form.Item>
                <Form.Item name="grade_name" label="年级" rules={[{ required: true, message: '年级不能为空' }]}>
                    <Input placeholder="请选择年级" readOnly={true} onClick={handleGrades} />
                </Form.Item>
                <Form.Item name="class_name" label="班级">
                    <Input placeholder="请选择年级" readOnly={true} onClick={handleClass} />
                </Form.Item>
                <Form.Item name="remark" label="备注">
                    <Input placeholder="请输入备注" />
                </Form.Item>
                <Form.Item name="zone_id" hidden label="校区">
                    <Input />
                </Form.Item>
                <Form.Item name="grade" hidden label="年级">
                    <Input />
                </Form.Item>
                <Form.Item name="class_num" hidden label="班级">
                    <Input />
                </Form.Item>
            </Form>
        </div>

        <Picker
            columns={[zones]}
            visible={visible}
            onConfirm={handleSelectedZone}
            onClose={() => {
                setVisible(false)
            }}
        />
        <Picker
            columns={[constant.grades]}
            visible={visibleGrades}
            onConfirm={handleSelectedGrade}
            onClose={() => {
                setVisibleGrades(false)
            }}
        />
        <Picker
            columns={[ClassNum]}
            visible={visibleClass}
            onConfirm={handleSelectedClass}
            onClose={() => {
                setVisibleClass(false)
            }}
        />
    </div>
}

export default Index
