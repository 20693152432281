/**
 * 404
 */
import styles from './index.module.less'

const NotFound = () => {
    console.log('4044')
    return <div className={styles.wrapper}>
        <div className={styles.box}>
            <h1 className={styles.note}>404 not found</h1>
        </div>
    </div>
}

export default NotFound
