/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Picker, Toast, Button, Form, Input, Dialog, Radio } from 'antd-mobile'
import api from "../../../api"
import styles from './index.module.less'
import helper from '../../../utils/helper.js'
import { useNavigate } from "react-router-dom"


function Index(props) {
    const params = helper.parseParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [zones, setZones] = useState([])
    const [record, setRecord] = useState(null)
    const [visible, setVisible] = useState(false)
    const [roleId, setRoleId] = useState(3)
    const toast = useRef(null)
    const loadZones = useCallback(async () => {
        const res = await api.getZones({ limit: 1000 })
        const _zones = []
        res.data.forEach((_item) => {
            _zones.push({ value: _item.id, label: _item.name })
        })
        setZones(_zones)
    }, [])
    useEffect(() => {
        loadZones()
    }, [])
    const loadStaff = useCallback(async (id) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgStaff(id)
        if (res.meta.code === 200) {
            setRecord(res.data)
        } else {

        }
        toast.current.close()
    }, [])

    useEffect(() => {
        if (params.id) {
            loadStaff(params.id)
        }
    }, [])

    useEffect(() => {
        if (record && zones.length) {
            const _zone = zones.find((_item) => _item.value === record.zone_id)
            const _data = {
                name: record.name,
                mobile: record.mobile,
                role_id: record.role_id,
                zone_id: record.zone_id,
            }
            if (_zone) {
                _data.zone = _zone.label
            }
            setRoleId(record.role_id)
            form.setFieldsValue(_data)
        }
        return () => {
        }
    }, [record, zones, form])


    const handleFinish = useCallback(async (value) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '处理中...',
            duration: 0
        })
        let res = null
        if (record) {
            res = await api.putOrgStaff(record.id, value)
        } else {
            res = await api.postOrgStaff(value)
        }
        if (res.meta?.code === 200) {
            navigate('/staffs')
            toast.current.close()
        } else {
            toast.current = Toast.show({
                icon: 'fail',
                content: res.meta.message,
                duration: 3000
            })
        }
    }, [record, navigate])

    const handleDelete = useCallback(async () => {
        if (record?.id) {
            const res = await api.deleteOrgStaff(record.id)
            if (res.meta?.code === 200) {
                navigate('/staffs')
            } else {
                toast.current = Toast.show({
                    icon: 'fail',
                    content: res.meta.message,
                    duration: 3000
                })
            }
        }
    }, [navigate, record])


    const initValue = useMemo(() => {
        return {
            role_id: 3
        }
    }, [])

    const handleFieldsChange = useCallback(async (val) => {
        const _filed = val[0]
        if (_filed['name'][0] === 'role_id') {
            if (+_filed['value']) {
                setRoleId(+_filed['value'])
            }
        }
    }, [])

    const handleZone = useCallback(async () => {
        setVisible(true)
    }, [])

    const handleSelectedZone = useCallback(async (val) => {
        if (val.length) {
            const _zone = zones.find((_item) => _item.value === val[0])
            form.setFieldsValue({
                'zone_id': _zone.value,
                'zone': _zone.label
            })
        }
    }, [form, zones])


    return <div className={styles.staffs}>
        <div className={styles.formWrap}>
            <Form
                name="form"
                form={form}
                initialValues={initValue}
                onFinish={handleFinish}
                onFieldsChange={handleFieldsChange}
                footer={
                    <>
                        <Button block type="submit" color="primary" shape="rounded" size="large">保存</Button>
                        {record?.id ? <div className={styles.del}><span onClick={() =>
                            Dialog.confirm({
                                content: '确认删除？',
                                onConfirm: async () => {
                                    handleDelete()
                                }
                            })
                        }>删除</span></div> : ''}
                    </>
                }
            >
                <Form.Item name="name" label="姓名"
                           rules={[{ required: true, message: '姓名不能为空' }]}>
                    <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item name="mobile" label="手机号" inputMode="decimal" type="tel"
                           rules={[{ required: true, message: '手机号错误' }]}>
                    <Input placeholder="请输入手机号" />
                </Form.Item>
                <Form.Item name="role_id" label="身份">
                    <Radio.Group>
                        <Radio value={2} className={styles.radio}>管理员</Radio>
                        <Radio value={3}>员工</Radio>
                    </Radio.Group>
                </Form.Item>
                {roleId === 3 ?
                    <Form.Item name="zone" label="所在校区" rules={[{ required: true, message: '校区不能为空' }]}>
                        <Input placeholder="请选择所在校区" readOnly={true} onClick={handleZone} />
                    </Form.Item>
                    : ''}
                <Form.Item name="zone_id" hidden label="校区">
                    <Input />
                </Form.Item>
            </Form>
        </div>

        <Picker
            columns={[zones]}
            visible={visible}
            onConfirm={handleSelectedZone}
            onClose={() => {
                setVisible(false)
            }}
        />
    </div>
}

export default Index
