/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Toast } from "antd-mobile"
import { useNavigate } from "react-router-dom"
import CountdownButton from '../../components/Countdown'
import styles from "./index.module.less"
import api from "../../api"

function Index(props) {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState(true)
    const toast = useRef(null)
    useEffect(() => {
        return () => {
        }
    }, [])
    const handleFinish = useCallback(async (values) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '登录中...',
            duration: 0
        })
        const res = await api.postSMSCodeToken({
            grant_type: 'code',
            client_id: 8,
            mobile: values.mobile.trim(),
            code: values.code
        })
        if (res.meta?.code === 200) {
            window.localStorage.setItem('access_token_h5', res.data.access_token)
            setTimeout(() => {
                navigate("/home")
            }, 300)
            toast.current.close()
        } else {
            Toast.show({
                icon: 'fail',
                content: res.meta.message,
                duration: 3000
            })
        }
    }, [navigate])

    const handleGetCode = useCallback(async () => {
        const val = form.getFieldsValue()
        if (val.mobile.trim()?.length === 11) {
            const res = await api.getSMSCode({ mobile: val.mobile.trim(), client_id: 8 })
            if (res.meta.code === 200) {
                Toast.show({
                    icon: 'success',
                    content: '短信已发送',
                })
                if (res.data?.code) {
                    form.setFieldValue('code', res.data.code)
                }
            } else {
                Toast.show({
                    icon: 'fail',
                    content: res.meta.message,
                })
                setDisabled(true)
            }
        } else {
            Toast.show({
                icon: 'fail',
                content: '手机号码错误',
            })
        }
    }, [form])
    const handleFieldsChange = useCallback(async (val) => {
        if (val[0].name[0] === 'mobile' && !val[0].value.startsWith('0') && val[0].value.trim().length === 11) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [])
    return <div className={styles.login}>
        <h3>西瓜学习</h3>
        <div className={styles.formWrap}>
            <Form
                name="form"
                form={form}
                onFinish={handleFinish}
                onFieldsChange={handleFieldsChange}
                footer={
                    <Button block type="submit" color="primary" shape="rounded" size="large">
                        提交
                    </Button>
                }
            >
                <Form.Item name="mobile" label="手机号" inputMode="decimal" type="tel"
                           rules={[{ required: true, message: '手机号错误' }]}>
                    <Input placeholder="请输入手机号" />
                </Form.Item>
                <Form.Item name="code" label="验证码"
                           inputMode="decimal"
                           extra={<CountdownButton disabled={disabled} onClick={handleGetCode}></CountdownButton>}
                           rules={[{ required: true, message: '验证码不能为空' }]}>
                    <Input placeholder="请输入验证码" />
                </Form.Item>
            </Form>
        </div>
    </div>

}

export default Index
