/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useState, useRef } from "react"
import styles from './index.module.less'
import { Toast, List, Button } from "antd-mobile"
import api from "../../api"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"


function Index(props) {
    const navigate = useNavigate()
    const currentZone = useSelector((state) => state.zones.current)
    const [list, setList] = useState([])
    const toast = useRef(null)
    const loadList = useCallback(async () => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgStudentsTasks(currentZone.id, { limit: 1000 })
        console.log(res)
        if (res.meta.code === 200) {
            setList(res.data)
        } else {

        }
        toast.current.close()
    }, [currentZone])

    useEffect(() => {
        if (currentZone?.id) {
            loadList()
        }
        return () => {
        }
    }, [currentZone])

    const handleDetail = useCallback(async (_id) => {
        navigate(`/students/detail?id=${_id}`)
    }, [navigate])


    return <div className={styles.charges}>
        <div className={styles.action}>
            <Button size="small" onClick={() => {
                navigate('/students/add')
            }}>添加</Button>
        </div>
        <List>
            {list.map(item => {
                const sum = <div className={styles.status}>
                    <span className={styles.red}>{item.not_learn_days}</span>
                    <span className={styles.yellow}>{item.not_finish_days}</span>
                    <span className={styles.green}>{item.finish_days}</span>
                </div>
                return <List.Item
                    key={item.id}
                    description={sum}
                    arrow={null}
                    onClick={() => {
                        handleDetail(item.id)
                    }}
                    extra={<span>{item.enabled ? '' : '已停用'}</span>}>
                    <div>
                        <span className={styles.name}>{item.name}</span>
                        <span className={styles.label}>{item.grade}年级</span>
                        <span className={styles.label}>{item.class_num}班</span>
                        <span className={styles.label}>{item.school}</span>
                    </div>
                </List.Item>
            })}
        </List>
    </div>
}

export default Index
