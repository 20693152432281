/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import { createSlice } from '@reduxjs/toolkit'

export const _slice = createSlice({
    name: 'stages',
    initialState: {
        list: [],
        current: null
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setList } = _slice.actions

export default _slice.reducer
