/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import { createSlice } from '@reduxjs/toolkit'

export const zonesSlice = createSlice({
    name: 'zones',
    initialState: {
        list: [],
        current: null
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload
        },
        setCurrent: (state, action) => {
            state.current = action.payload
        }
    },
})

export const { setList, setCurrent } = zonesSlice.actions

export default zonesSlice.reducer
