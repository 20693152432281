/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import api from "../../api"
import Week from '../../components/Week'
import Card from './card'
import CardList from './cardList'
import styles from './index.module.less'
import { Toast } from "antd-mobile"


function Index(props) {
    const currentZone = useSelector((state) => state.zones.current)
    const [currentDate, setCurrentDate] = useState(dayjs())
    const [stat, setStat] = useState([])
    const mounted = useRef(false)
    const toast = useRef(null)
    const loadStat = useCallback(async () => {
        if (currentDate && currentZone?.id) {
            toast.current = Toast.show({
                icon: 'loading',
                content: '处理中...',
                duration: 0
            })
            const _dateID = currentDate.format('YYYYMMDD')
            const res = await api.getZoneUserStat(currentZone.id, { date_id: _dateID })
            setStat(res.data)
            toast.current.close()
        }
    }, [currentZone, currentDate])
    useEffect(() => {
        mounted.current = true
        loadStat()
        return () => {
            mounted.current = false
        }
    }, [currentZone, currentDate])

    const handleChangeDate = useCallback((_date) => {
        setCurrentDate(_date)
    }, [loadStat])

    return <div>
        <Week current={currentDate} onChange={handleChangeDate} />
        <div className={styles.main}>
            <Card data={stat} />
            <CardList data={stat} currentDate={currentDate} />
        </div>
    </div>
}

export default Index
