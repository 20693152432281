/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import { useCallback, useEffect, useMemo, useState } from "react"
import api from "../api"
import { useDispatch, useSelector } from "react-redux"
import { List, NavBar, Picker } from 'antd-mobile'
import { setList, setCurrent } from '../reducers/Zones'
import { DownOutline, MoreOutline } from 'antd-mobile-icons'
import styles from './Header.module.less'
import { useNavigate } from "react-router-dom"

function Header(props) {
    const navigate = useNavigate()
    const zones = useSelector((state) => state.zones.list)
    const currentZone = useSelector((state) => state.zones.current)
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [navVisible, setNavVisible] = useState(false)
    const loadZones = useCallback(async () => {
        const me = await api.getOrgStaffMe()
        if (me.data.role_id === 3) {
            // 员工只能看到自己的校区
            dispatch(setList([me.data.zone]))
            dispatch(setCurrent(me.data.zone))
        } else {
            const res = await api.getZones({ limit: 50 })
            dispatch(setList(res.data))
            if (res.data.length) {
                dispatch(setCurrent(res.data[0]))
            }
        }
    }, [dispatch])

    useEffect(() => {
        loadZones()
        return () => {
        }
    }, [])
    const options = useMemo(() => {
        const _options = []
        zones.forEach((_item) => {
            _options.push({ value: _item.id, label: _item.name })
        })
        return _options
    }, [zones])

    const handleVisible = useCallback(async () => {
        setVisible(!visible)
    }, [visible])

    const handleSelected = useCallback(async (val) => {
        if (val.length) {
            let _current = zones.find((_item) => _item.id === val[0])
            if (_current) {
                dispatch(setCurrent(_current))
            }
        }
    }, [dispatch, zones])

    const checkHide = useCallback((e) => {
        if (!e.target.closest(`.${styles.menu}`) && !e.target.closest(`.${styles.menuIcon}`)) {
            setNavVisible(false)
        }
    }, [])


    useEffect(() => {
        document.body.addEventListener('click', checkHide)
        return () => {
            document.body.removeEventListener('click', checkHide)
        }
    }, [])

    const handleNavVisible = useCallback(() => {
        setNavVisible(!navVisible)
    }, [navVisible])

    const handleLogout = useCallback(async () => {
        setNavVisible(!navVisible)
        window.localStorage.removeItem('access_token_h5')
        navigate('/login')
    }, [navigate])

    const right = (
        <div style={{ fontSize: 24 }}>
            <MoreOutline onClick={handleNavVisible} className={styles.menuIcon} />
        </div>
    )

    return <div className={styles.header}>
        <NavBar className={styles.navBar} back={currentZone?.name ? <>{currentZone?.name}<DownOutline /></> : ''}
                onBack={handleVisible}
                right={right}
                backArrow={false}>{props.route.title}</NavBar>
        {/*<a href={null} className={styles.navLink} onClick={() => {*/}
        {/*    navigate('/me')*/}
        {/*}}>我的</a>*/}
        {/*{currentZone ?*/}
        {/*    <div className={styles.zone}>*/}
        {/*        {zones.length > 1 ?*/}
        {/*            currentZone ? <a href={null} onClick={handleVisible}>{currentZone.name}<DownOutline /></a> : ''*/}
        {/*            : <span>{currentZone.name}</span>}*/}
        {/*    </div>*/}
        {/*    : ''}*/}
        {options.length ?
            <Picker
                columns={[options]}
                visible={visible}
                onConfirm={handleSelected}
                onClose={() => {
                    setVisible(false)
                }}
            />
            : ''}
        <div className={styles.menu + ` ${navVisible ? styles.show : ''}`}>
            <List>
                <List.Item arrow={false} onClick={() => {
                    setNavVisible(false)
                    navigate('/home')
                }}>首页</List.Item>
                <List.Item arrow={false} onClick={() => {
                    setNavVisible(false)
                    navigate('/me')
                }}>我的</List.Item>
                <List.Item arrow={false} onClick={handleLogout}>退出</List.Item>
            </List>
        </div>
    </div>
}

export default Header
