import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Provider } from 'react-redux'
import store from './store'
import './App.less'
import router from './router'

document.title = '西瓜学习'

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {router.map((route, idx) => {
                        if (!route.layout) {
                            return <Route key={idx} path={route.path} exact={route.exact}
                                          element={<route.component route={route} authed={true} />} />
                        } else {
                            return <Route key={idx} path={route.path} exact={route.exact}
                                          element={<route.layout route={route} authed={true}>
                                              <route.component />
                                          </route.layout>}
                            />
                        }
                    })}
                </Routes>
            </BrowserRouter>
        </Provider>
    )
}

export default App
