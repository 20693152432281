/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useState, useRef } from "react"
import dayjs from 'dayjs'
import styles from './index.module.less'
import { Toast, List, Dialog } from "antd-mobile"
import api from "../../api"
import constants from "../../constants"


function Index(props) {
    const [list, setList] = useState([])
    const toast = useRef(null)
    const loadList = useCallback(async () => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getCharges({ limit: 200 })
        if (res.meta.code === 200) {
            setList(res.data)
        } else {

        }
        toast.current.close()
    }, [])


    useEffect(() => {
        loadList()
        return () => {
        }
    }, [])

    const handleTip = useCallback(async () => {
        Dialog.alert({
            content: <div className={styles.tipWrap}>
                <h4>费用说明</h4>
                <p>按自然月计算，每月1日 00：01一次性扣除启用账号本月费用，每个启用账号，500豆/月；若不足一个月，则按剩余天数换算，一次性扣除本月费用；</p>
                <p>
                    换算原则：<br />
                    启用账号当天算在内一并扣除；<br />
                    开通新账号当天不算在内<br />
                    换算后不整，则进行四舍五入
                </p>
            </div>,
            closeOnMaskClick: true,
        })
    }, [])


    return <div className={styles.charges}>
        <div className={styles.tip}>学习豆：{list.length ? list[0].remaining_amount : 0}<a href={null}
                                                                                       onClick={handleTip}>费用说明</a>
        </div>
        <List>
            {list.map(item => {
                const _date = dayjs.unix(item.create_time)
                const _type = constants.chargesType.find((_s) => _s.id === item.type)
                return <List.Item
                    key={item.id}
                    prefix={
                        <div className={styles.date}>
                            <div className={styles.day}><strong>{_date.format('DD')}</strong>/{_date.format('MM')}</div>
                            <div className={styles.year}>{_date.format('YYYY年')}</div>
                        </div>

                    }
                    description={item.description}
                >
                    <div className={styles.item}>
                        {_type.name}<strong>{item.amount}</strong>
                    </div>
                </List.Item>
            })}
        </List>
    </div>
}

export default Index
