/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card } from 'antd-mobile'
import styles from './index.module.less'
import { useEffect } from "react"

function Index(props) {
    const { data } = props
    useEffect(() => {
    }, [])
    return <div className={styles.summary}>
        <Card>
            <div className={styles.top}>
                <div className={styles.item}>
                    <span className={styles.label}>已学卡片数</span>
                    <span className={styles.num}>{data?.learned_count || 0}</span>
                </div>
                <div className={styles.item}>
                    <span className={styles.label}>已学卡片数</span>
                    <span className={styles.num}>{data?.k12_learned_count || 0}</span>
                </div>
                <div className={styles.item}>
                    <span className={styles.label}>已学卡片数</span>
                    <span className={styles.num}>{data?.specific_learned_count || 0}</span>
                </div>
            </div>
        </Card>
    </div>
}

export default Index

