/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/28
 */

import styles from './index.module.less'
import { useEffect, useState } from "react"

const Init = { red: 0, yellow: 0, green: 0 }

function Index(props) {
    const data = props.data
    const [stat, setStat] = useState({ ...Init })
    useEffect(() => {
        const _stat = { ...Init }
        data.forEach((_item) => {
            if (_item.stat.task_status === 1) {
                _stat.red++
            }
            if (_item.stat.task_status === 2) {
                _stat.yellow++
            }
            if (_item.stat.task_status === 3) {
                _stat.green++
            }
        })
        setStat(_stat)
    }, [data])
    return <div className={styles.card}>
        <div className={`${styles.item}`}>
            <div className={styles.label}>红码</div>
            <div className={styles.num}>{stat.red}<span>人</span></div>
        </div>
        <div className={`${styles.item} ${styles.yellow}`}>
            <div className={styles.label}>黄码</div>
            <div className={styles.num}>{stat.yellow}<span>人</span></div>
        </div>
        <div className={`${styles.item} ${styles.green}`}>
            <div className={styles.label}>绿码</div>
            <div className={styles.num}>{stat.green}<span>人</span></div>
        </div>
    </div>
}

export default Index
