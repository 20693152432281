/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

import axios from 'axios'
import { Toast } from 'antd-mobile'
import helper from '../utils/helper'
import config from '../config'

const baseURL = config.apiHost
// if (process.env.NODE_ENV === 'development') {
//     baseURL = 'http://dev-api.momo.im/admin/proxy/internal?uri='
// }

export const request = axios.create({
    timeout: 60000,
})
// Add a request interceptor
request.interceptors.request.use(function(config) {
    // Do something before request is sent
    const accessToken = localStorage.getItem('access_token_h5')
    config.headers['Authorization'] = `Bearer ${accessToken}`
    return config
}, function(error) {
    // Do something with request error
    return Promise.reject(error)
})
// Add a response interceptor
request.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200) {
        if (response.data) {
            return response.data
        } else {
            alert('server error')
        }
    } else {
        return response
    }
}, function(error) {
    // console.log('error', arguments)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const res = error.response
    console.log(error)
    if (res && res.data && res.data.meta) {
        if (res.data.meta.code === 401 || res.data.meta.code === 101054) {
            // 未登录
            window.localStorage.removeItem('access_token_h5')
            window.location.href = '/login'
        } else {
            Toast.show({
                content: res.data.meta.message || error.message || 'network error connection refused'
            })
        }
        return res.data
        // alert(res.data.meta.message)
    } else {
        Toast.show({
            duration: 0,
            content: error.message || 'network error connection refused'
        })
        return Promise.reject(error)
    }
})
export const formatUrl = (url, query) => {
    if (query) {
        url = helper.format(url, query)
    }
    if (!url.startsWith('http')) {
        url = `${baseURL}${url}`
    }
    return url
}

export default {
    // 登录
    getSMSCode: async (data) => {
        return request.post(formatUrl('/org/api/auth/verify_code'), data)
    },
    // 验证码登录
    postSMSCodeToken: async (data) => {
        return request.post(formatUrl('/org/api/auth/token'), data)
    },
    // 校区列表
    getZones: async (params) => {
        return request.get(formatUrl('/org/api/zones'), { params })
    },
    // 获取机构用户信息
    getOrgStaffMe: async (params) => {
        return request.get(formatUrl('/org/api/staffs/me'), { params })
    },
    // 获取校区列表
    getOrgZones: async (params) => {
        return request.get(formatUrl('/org/api/zones'), { params })
    },
    // 获取校区详情
    getOrgZone: async (id) => {
        return request.get(formatUrl('/org/api/zones/{id}', { id }), {})
    },
    // 修改校区
    putOrgZone: async (id, data) => {
        return request.put(formatUrl('/org/api/zones/{id}', { id }), data)
    },
    // 删除校区
    deleteOrgZone: async (id) => {
        return request.delete(formatUrl('/org/api/zones/{id}', { id }), {})
    },
    // 保存校区
    postOrgZones: async (data) => {
        return request.post(formatUrl('/org/api/zones'), data)
    },

    // 获取员工列表
    getOrgStaffs: async (params) => {
        return request.get(formatUrl('/org/api/staffs'), { params })
    },
    // 获取校区详情
    getOrgStaff: async (id) => {
        return request.get(formatUrl('/org/api/staffs/{id}', { id }), {})
    },
    // 修改校区
    putOrgStaff: async (id, data) => {
        return request.put(formatUrl('/org/api/staffs/{id}', { id }), data)
    },
    // 删除校区
    deleteOrgStaff: async (id) => {
        return request.delete(formatUrl('/org/api/staffs/{id}', { id }), {})
    },
    // 保存校区
    postOrgStaff: async (data) => {
        return request.post(formatUrl('/org/api/staffs'), data)
    },
    // 获取省市列表
    getAreas: async (params) => {
        return request.get(formatUrl('/org/api/const/areas'), { params })
    },
    // 获取消费记录
    getCharges: async (params) => {
        return request.get(formatUrl('/org/api/charges'), { params })
    },
    // 获取学生列表
    getOrgStudentsTasks: async (zone_id, params) => {
        return request.get(formatUrl('/org/api/stats/zones/{zone_id}/user_tasks', { zone_id }), { params })
    },
    // 获取学生统计
    getOrgStudentsTasksStats: async (user_id, params) => {
        return request.get(formatUrl('/org/api/stats/users/{user_id}/user_tasks', { user_id }), { params })
    },
    // 获取学生学习概况
    getOrgStudentSummary: async (user_id, params) => {
        return request.get(formatUrl('/org/api/stats/users/{user_id}/summary', { user_id }), { params })
    },
    // 获取学生
    getOrgStudent: async (id) => {
        return request.get(formatUrl('/org/api/users/{id}', { id }))
    },
    // 修改学生
    putOrgStudent: async (id, data) => {
        return request.put(formatUrl('/org/api/users/{id}', { id }), data)
    },
    // 启禁用学生
    enableOrgStudent: async (id, data) => {
        return request.post(formatUrl('/org/api/users/{id}/enable', { id }), data)
    },
    // 保存校区
    postOrgStudent: async (data) => {
        return request.post(formatUrl('/org/api/users'), data)
    },
    // 获取学生列表
    getStages: async (params) => {
        return request.get(formatUrl('/org/api/const/stages'), { params })
    },
    // 获取费用
    getAmount: async (params) => {
        return request.get(formatUrl('/org/api/charges/amount'), { params })
    },
    // 获取首页统计
    getZoneUserStat: async (zone_id, params) => {
        return request.get(formatUrl('/org/api/stats/zones/{zone_id}/users', { zone_id }), { params })
    }
}


