/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
import dayjs from "dayjs"
import styles from './index.module.less'
import { useEffect } from "react"

const days = []
const today = dayjs()
for (let i = 1; i < 8; i++) {
    days.push(dayjs().startOf('week').add(i, 'day'))
}
console.log(days)

function Index(props) {
    useEffect(() => {
        props.onChange(today)
    }, [])
    return <div className={styles.week}>
        <div className={styles.current}>{props.current.format('YYYY年MM月')}</div>
        <ul>
            {days.map((_day, _idx) => {
                const cls = props.current.get('date') === _day.get('date') ? styles.active : ''
                return <li key={_idx} className={cls}>
                    <span className={styles.day}>
                    {"日一二三四五六".charAt(_day.get('day'))}
                    </span>
                    <span className={styles.date} onClick={() => {
                        props.onChange(_day)
                    }}>
                    {_day.get('date')}
                    </span>
                </li>
            })}
        </ul>
    </div>
}

export default Index
