/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/23
 */
// import { lazy } from 'react'

import BaseLayout from './layouts/Base'
import Login from './pages/login'
import Home from './pages/home'
import Me from './pages/me'
import Zones from './pages/zones'
import ZonePut from './pages/zones/put'
import Staffs from './pages/staffs'
import StaffPut from './pages/staffs/put'
import Charges from './pages/charges'
import Students from './pages/students'
import StudentPut from './pages/students/put'
import StudentDetail from './pages/students/detail'
import NotFound from './pages/404'

export default [
    {
        path: '/',
        component: Home,
        exact: true,
        title: '首页',
        layout: BaseLayout
    },
    {
        path: '/home',
        component: Home,
        exact: true,
        title: '首页',
        layout: BaseLayout
    },
    {
        path: '/me',
        component: Me,
        exact: true,
        title: '我的',
        layout: BaseLayout
    },
    {
        path: '/zones',
        component: Zones,
        exact: true,
        title: '校区管理',
        layout: BaseLayout
    },
    {
        path: '/zones/add',
        component: ZonePut,
        exact: true,
        title: '添加校区',
        layout: BaseLayout
    },
    {
        path: '/zones/edit',
        component: ZonePut,
        exact: true,
        title: '修改校区',
        layout: BaseLayout
    },

    {
        path: '/staffs',
        component: Staffs,
        exact: true,
        title: '员工管理',
        layout: BaseLayout
    },
    {
        path: '/staffs/add',
        component: StaffPut,
        exact: true,
        title: '添加员工',
        layout: BaseLayout
    },
    {
        path: '/staffs/edit',
        component: StaffPut,
        exact: true,
        title: '修改员工',
        layout: BaseLayout
    },
    {
        path: '/charges',
        component: Charges,
        exact: true,
        title: '消费记录',
        layout: BaseLayout
    },
    {
        path: '/students',
        component: Students,
        exact: true,
        title: '学生管理 ',
        layout: BaseLayout
    },
    {
        path: '/students/add',
        component: StudentPut,
        exact: true,
        title: '添加学生',
        layout: BaseLayout
    },
    {
        path: '/students/edit',
        component: StudentPut,
        exact: true,
        title: '修改学生',
        layout: BaseLayout
    },
    {
        path: '/students/detail',
        component: StudentDetail,
        exact: true,
        title: '学生详情',
        layout: BaseLayout
    },
    {
        path: '/login',
        component: Login,
        exact: true,
        layout: false
    },
    {
        path: '*',
        component: NotFound
    }
]
