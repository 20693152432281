/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/24
 */

export default {
    roleKV: {
        '1': '主账号',
        '2': '管理员',
        '3': '员工',
    },
    grades: [{
        value: 1,
        label: '一年级'
    }, {
        value: 2,
        label: '二年级'
    }, {
        value: 3,
        label: '三年级'
    }, {
        value: 4,
        label: '四年级'
    }, {
        value: 5,
        label: '五年级'
    }, {
        value: 6,
        label: '六年级'
    }, {
        value: 7,
        label: '七年级'
    }, {
        value: 8,
        label: '八年级'
    }, {
        value: 9,
        label: '九年级'
    }],
    chargesType: [{
        "id": 1,
        "name": "充值学习豆"
    }, {
        "id": 2,
        "name": "开通学生账号"
    }, {
        "id": 3,
        "name": "月费"
    }, {
        "id": 4,
        "name": "启用学生账号"
    }]
}
