/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Cascader, Toast, Button, Form, Input, Dialog } from 'antd-mobile'
import api from "../../../api"
import styles from './index.module.less'
import helper from '../../../utils/helper.js'
import { useNavigate } from "react-router-dom"


function Index(props) {
    const params = helper.parseParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [areas, setAreas] = useState([])
    const [record, setRecord] = useState(null)
    const [visible, setVisible] = useState(false)
    const toast = useRef(null)
    const cites = useRef({})

    const loadAreas = useCallback(async () => {
        const res = await api.getAreas()
        if (res.meta.code === 200) {
            const _areas = []
            res.data.forEach((_item) => {
                cites.current[_item.id] = _item.name
                if (_item.parent === 0) {
                    _areas.push({ value: _item.id, label: _item.name, children: [] })
                }
            })

            _areas.forEach((_p) => {
                res.data.forEach((_item) => {
                    if (_item.parent === _p.value) {
                        _p.children.push({ value: _item.id, label: _item.name })
                    }
                })
            })
            setAreas(_areas)
        } else {

        }
    }, [])

    useEffect(() => {
        loadAreas()
    }, [])

    const loadZone = useCallback(async (id) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getOrgZone(id)
        if (res.meta.code === 200) {

            setRecord(res.data)
        } else {

        }
        toast.current.close()
    }, [form])

    useEffect(() => {
        if (params.id) {
            loadZone(params.id)
        }
    }, [])

    useEffect(() => {
        if (areas.length && record && cites.current[record.province_id]) {
            const _data = {
                name: record.name,
                province_id: record.province_id,
                city_id: record.city_id,
                address: record.address,
                remark: record.remark,
                city: `${cites.current[record.province_id]}${cites.current[record.city_id]}`
            }
            form.setFieldsValue(_data)
        }
        return () => {
        }
    }, [record, form, areas])


    const handleFinish = useCallback(async (value) => {
        toast.current = Toast.show({
            icon: 'loading',
            content: '处理中...',
            duration: 0
        })
        let res = null
        if (record) {
            res = await api.putOrgZone(record.id, value)
        } else {
            res = await api.postOrgZones(value)
        }
        if (res.meta?.code === 200) {
            navigate('/zones')
            toast.current.close()
        } else {
            toast.current = Toast.show({
                icon: 'fail',
                content: res.meta.message,
                duration: 3000
            })
        }
    }, [record, navigate])

    const handleDelete = useCallback(async () => {
        if (record?.id) {
            const res = await api.deleteOrgZone(record.id)
            if (res.meta?.code === 200) {
                navigate('/zones')
            } else {
                toast.current = Toast.show({
                    icon: 'fail',
                    content: res.meta.message,
                    duration: 3000
                })
            }
        }
    }, [navigate, record])

    const handleAreas = useCallback(async () => {
        setVisible(true)
    }, [])

    const handleSelectedCity = useCallback(async (val) => {
        if (val.length === 2) {
            form.setFieldsValue({
                'province_id': val[0],
                'city_id': val[1],
                'city': `${cites.current[val[0]]}${cites.current[val[1]]}`
            })
        }
    }, [form])

    const initValue = useMemo(() => {
        return {
            province_id: 0,
            city_id: 0
        }
    }, [])


    return <div className={styles.zones}>
        <div className={styles.formWrap}>
            <Form
                name="form"
                form={form}
                initialValues={initValue}
                onFinish={handleFinish}
                footer={
                    <>
                        <Button block type="submit" color="primary" shape="rounded" size="large">
                            保存
                        </Button>
                        {record?.id ? <div className={styles.del}><span onClick={() =>
                            Dialog.confirm({
                                content: '确认删除该校区？',
                                onConfirm: async () => {
                                    handleDelete()
                                }
                            })
                        }>删除</span></div> : ''}
                    </>
                }
            >
                <Form.Item name="name" label="校区名称"
                           rules={[{ required: true, message: '校区名称不能为空' }]}>
                    <Input placeholder="请输入校区名称" />
                </Form.Item>
                <Form.Item name="city" label="所在地" extra={<Button size="small" onClick={handleAreas}>选择</Button>}>
                    <Input placeholder="请输入所在地" disabled={true} />
                </Form.Item>
                <Form.Item name="address" label="地址">
                    <Input placeholder="请输入地址" />
                </Form.Item>
                <Form.Item name="remark" label="备注">
                    <Input placeholder="请输入备注" />
                </Form.Item>
                <Form.Item name="province_id" hidden label="省份">
                    <Input />
                </Form.Item>
                <Form.Item name="city_id" hidden label="市">
                    <Input />
                </Form.Item>
            </Form>
        </div>
        <Cascader
            options={areas}
            visible={visible}
            onConfirm={handleSelectedCity}
            onClose={() => {
                setVisible(false)
            }}
        />
    </div>
}

export default Index
