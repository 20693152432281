/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/2/28
 */

import styles from './index.module.less'
import { Card, Empty } from 'antd-mobile'
import { useEffect, useState, useCallback } from "react"
import helper from '../../../utils/helper'
import { useNavigate } from "react-router-dom"
import dayjs from 'dayjs'

const Init = { red: [], yellow: [], green: [] }
const KV = { 'red': '红色', 'yellow': '黄色', 'green': '绿色' }

function Index(props) {
    const { currentDate } = props
    const navigate = useNavigate()
    const data = props.data
    const [stat, setStat] = useState({ ...Init })
    useEffect(() => {
        if (data && data.length) {
            const _stat = { red: [], yellow: [], green: [] }
            data.forEach((_item) => {
                if (_item.stat.task_status === 1) {
                    _stat.red.push(_item)
                }
                if (_item.stat.task_status === 2) {
                    _stat.yellow.push(_item)
                }
                if (_item.stat.task_status === 3) {
                    _stat.green.push(_item)
                }
            })
            setStat(_stat)
        } else {
            setStat(Init)
        }
    }, [data])
    const handleDetail = useCallback((_id) => {
        navigate(`/students/detail?id=${_id}`)
    }, [navigate])

    return <div className={styles.cardList}>
        {['red', 'yellow', 'green'].map((_key) => {
            return <div className={`${styles.item}`} key={_key}>
                <h4 className={`${_key}`}>{KV[_key]}</h4>
                <div className={styles.list}>
                    {stat[_key].map((_item) => {
                        return <Card
                            key={_item.id}
                            title={<div> {_item.name} </div>}
                            onClick={() => {
                                handleDetail(_item.id)
                            }}
                            extra={'green' !== _key && dayjs().get('date') === currentDate.get('date') ?
                                <div>待学习：{_item.stat.learn_count}&nbsp;&nbsp;待复习：{_item.stat.review_count}</div> : ''}>
                            <div className={styles.numWrap}>
                                <div className={styles.num}>
                                    <div className={styles.label}>学习完成</div>
                                    <div>{_item.stat.learned_count}/{_item.stat.total_learned_count}</div>
                                </div>
                                <div className={styles.num}>
                                    <div className={styles.label}>复习完成</div>
                                    <div>{_item.stat.reviewed_count}/{_item.stat.total_reviewed_count}</div>
                                </div>
                                <div className={styles.num}>
                                    <div className={styles.label}>学习时长</div>
                                    <div>{helper.formatDuration(_item.stat.reviewed_duration + _item.stat.question_duration + _item.stat.learned_duration)}</div>
                                </div>
                            </div>
                        </Card>
                    })}
                    {!stat[_key].length ?
                        <Empty description="暂无数据" />
                        : ''}
                </div>
            </div>
        })}
    </div>
}

export default Index
