import { configureStore } from '@reduxjs/toolkit'
import zonesReducer from '../reducers/Zones'
import stagesReducer from '../reducers/Stages'

export default configureStore({
    reducer: {
        zones: zonesReducer,
        stages: stagesReducer
    },
})
